.request-block {
    background-image: url(/images/request_background.png);
    background-size: cover;
    margin-top: 78px;
    padding: 145px 0px;
    text-align: center;
    height: 600px;
}

.request-consultation-button {
    background-color: #dd8620;
    border-radius: 30px;
    margin-top: 47px;
    padding: 16px 24px;
    transition: 300ms;
}

.request-consultation-button:hover {
    padding: 20px 36px;
    background-color: #dd913a;
    margin-top: 45px;
    word-spacing: 12px;
    border-radius: 40px;
}

.request-title {
    color: #ff8a00;
    font-family: var(--font-family-mont-bold);
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 0;
    opacity: 0.7;
}

.request-content {
    color: var(--white);
    font-family: var(--font-family-mont-regular);
    font-size: var(--font-size-xl);
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 30px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
}