.header {
    align-items: center;
    background-color: var(--eerie-black);
    border: 1px none;
    display: flex;
    height: 87px;
    padding: 10px 12px;
    width: 100%;
    max-width: 100vw;
    z-index: 9999;
    align-self: center;
    justify-content: space-evenly;
    position: fixed;
    top: 0;
}

.burger-header-dropdown {
    position: absolute;
    background-color: var(--eerie-black);
    width: 200px;
    right: 20px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 10px;
}

.burger-option-item {
    cursor: pointer;
    margin: 5px;
    display: flex;
    column-gap: 5px;
}

.burger-header-subopt {
    padding-left: 20px;
}

.request-button {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    background-color: var(--denim);
    border: 1px none;
    border-radius: 6px;
    box-shadow: 0px 4px 4px #00000040;
    cursor: pointer;
    transition: 400ms;
    padding: 10px 10px;
}

.request-button:hover {
    padding: 12px 15px;
}

.header-option {
    display: flex;
    column-gap: 20px;
    flex-flow: row wrap;
    align-items: center;
    width: 900px;
}

.header-option-item {
    position: relative;
    cursor: pointer;
}

.language-switch {
    display: inline-block;
    cursor: pointer;
}

.primary-white-logo {
    padding: 0 10px;
    height: 105px;
}

.product-option {
    width: 120px;
    height: auto;
    position: absolute;
    border-radius: 10px;
    top: 60px;
    left: 550px;
    display: flex;
    flex-flow: column nowrap;
    background: var(--eerie-black);
    z-index: 999;
}

.product-option-no {
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
    padding: 10px 10px;
}

.product-option-yes {
    border-radius: 5px;
    color: var(--denim) !important;
    cursor: pointer;
    padding: 10px 10px;
}

.product-option-no:hover {
    background-color: var(--denim);
}

.product-option-yes:hover {
    background-color: var(--white);
}

.header-underline {
    height: 3px;
    border-radius: 10px;
    margin-top: 2px;
    width: auto;
    background: linear-gradient(to left top, var(--white), var(--pizazz), var(--red));
}