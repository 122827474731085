/* About Company */
.about-company-title {
    background-image: url(/images/about-company-bg.png);
    height: 460px;
    text-align: center;
    padding-top: 160px;
    background-size: cover;
}

.about-company-content {
    padding: 100px 0px;
    line-height: 1.5;
    text-align: center;
}

.about-company-culture-flex {
    display: flex;
    flex-flow: row wrap;
    column-gap: 100px;
    row-gap: 40px;
    justify-content: center;
    margin-top: 60px;
}

.about-company-timeline {
    background-image: url(/images/about-timeline-bg2.png);
    padding: 50px 0px;
    margin-bottom: 100px;
}

.about-timeline-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 40px;
    justify-content: space-around;
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.about-timeline-item-left {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 10px;
    text-align: left;
    width: 500px;
    max-width: 60vw;
}

.about-timeline-item-right {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 10px;
    text-align: right;
    width: 500px;
    max-width: 60vw;
}

.about-timeline-year {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
}


/* About Team */
.about-team-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 60px;
    column-gap: 80px;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 200px;
}

.about-team-item {
    background-color: var(--thunder);
    border: 1px solid;
    border-radius: 20px;
    height: 400px;
    padding: 20px 20px;
    padding-top: 50px;
    width: 300px;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 50px;
    transition: 100ms;
    position: relative;
    cursor: pointer;
}

.about-team-intro {
    line-height: 1.2;
    text-align: left;
}

.about-linkedin {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.about-team-avatar {
    border: 1px solid white;
    border-radius: 60px;
    height: 120px;
    width: 120px;
}

.about-team-transition {
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 138.03, 0) 100%);
    border-radius: 175px;
    filter: blur(80px);
    height: 350px;
    width: 420px;
    position: absolute;
}

.about-team-stats {
    display: flex;
    flex-flow: row wrap;
    row-gap: 40px;
    column-gap: 200px;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 100px;
}

.about-team-stats-item {
    position: relative;
    text-align: center;
    width: 450px;
    height: 350px;
    margin-top: 50px;
}


/* About Career */
.about-career-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 40px;
    column-gap: 40px;
    justify-content: center;
    margin-bottom: 50px;
}

.about-career-item {
    width: 360px;
}

.about-divider {
    margin-top: 0px;
    margin-bottom: 60px;
    height: 5px;
}

.about-career-position {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
}

.about-hl {
    transition: 300ms;
    border-radius: 2%;
}

.about-hl:hover {
    box-shadow: 5px -20px 70px 15px rgb(61, 133, 228);
}