.title-image {
    position: relative;
    width: 100%;
    background-image: url(/images/solution_airports_big.png);
    background-color: var(--eerie-black);
    background-size: cover;
    border: 0px;
}

.solution-bg {
    background-image: url(/images/solution_overview_bg.png);
    background-size: 100%;
    padding: 150px 0px;
    text-align: center;
    font-size: 36px;
}

.solution-bg-text {
    width: 900px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
}

.drone-image {
    position: absolute;
    left: 120px;
    top: 80px;
}

.solution-workflow {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 100px;
}

.solution-workflow-item {
    position: relative;
    width: 350px;
}

.situation {
    position: relative;
    padding: 100px 20px;
    column-gap: 150px;
    align-items: center;
}

.blue-button {
    position: absolute;
    align-items: center;
    background-color: var(--denim);
    border-radius: 30px;
    padding: 16px 14px;
    cursor: pointer;
    z-index: 99;
    letter-spacing: 0;
}

.situation-threats {
    width: 500px;
    z-index: 99;
}

.word-of-mouth {
    padding: 100px 20px;
}

.quotation-mark {
    position: absolute;
    top: -30px;
}

.comment-flex {
    display: flex;
    flex-flow: row wrap;
    column-gap: 30px;
    justify-content: flex-start;
}

.comment-card {
    position: relative;
    width: 720px;
    max-width: 80vw;
    height: 380px;
    background-color: var(--denim);
    box-shadow: 10px 10px 10px 5px #00000040;
    padding: 14px 36px;
}

.comment-words {
    position: relative;
    padding: 20px 20px;
    height: 200px;
    text-align: center;
}

.solution-slide-img {
    max-height: 500px;
    width: 888px;
}

.sol-fea-item {
    width: 600px;
    max-width: 80vw;
}

.sol-fea-text {
    text-align: center;
    padding: 0px 60px;
    line-height: 1.5;
}

.sol-fea-img {
    height: 150px;
}

.sol-img-slide {
    margin-top: 100px;
    max-width: 80vw;
    position: relative;
    flex-flow: row nowrap;
    align-items: center;
}

.sol-img-rightbar {
    background-color: rgba(46, 46, 46, 0.64);
    border: 1px solid #484848;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    flex-flow: column;
    row-gap: 0px;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 500px;
}

.sol-device-trans {
    background-color: var(--steel-blue);
    border-radius: 10px;
    filter: blur(60px);
    height: 100px;
    left: 41px;
    position: absolute;
    top: 5px;
    width: 100px;
}