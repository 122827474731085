.contact-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 30px;
    column-gap: 50px;
    justify-content: center;
}

.contact-info {
    display: flex;
}