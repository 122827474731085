img {
    object-fit: contain;
}

a {
    cursor: pointer;
}

button {
    cursor: pointer;
    border: 0px;
}

li {
    margin: 20px 0;
}

textarea {
    resize: none;
}

h1 {
    margin-top: 10px;
    margin-bottom: 30px;
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: 60px;
    font-weight: 700;
    font-style: normal;
}

h2 {
    margin-top: 10px;
    margin-bottom: 30px;
    color: var(--denim);
    font-family: var(--font-family-mont-bold);
    font-size: 48px;
    font-weight: 700;
    font-style: normal;
}

h3 {
    margin-top: 10px;
    margin-bottom: 30px;
    color: var(--white);
    font-family: var(--font-family-mont-semibold);
    font-weight: 600;
    font-style: normal;
    text-align: center;
    font-size: 36px;
}

p {
    margin-top: 0;
    margin-bottom: 24px;
}

table,
th,
td {
    border: 1px solid #9CA9B3;
    text-align: center;
    font-family: 'Mont-SemiBold';
    padding: 8px 16px;
}

th {
    font-family: 'Mont-Bold';
}

table {
    max-width: 90vw;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
}

.align-right {
    margin-right: 0;
    margin-left: auto;
}

.row-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.decoy {
    position: relative;
    top: 400px;
    height: 500px;
}

.common-wrapper {
    width: var(--max-width);
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

/* The Modal (background) */
.my-modal {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.my-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 30px;
    border: 5px solid rgb(230, 193, 193);
    border-radius: 20px;
    max-width: 80%;
    width: 600px;
    min-height: 200px;
    /* Could be more or less, depending on screen size */
}

.webinar-video-modal {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.webinar-video-modal-content {
    background-color: var(--thunder);
    margin: 2% auto;
    padding: 30px;
    max-width: 65%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .webinar-video-modal-content {
        max-width: 95%;
    }
}

.video-container {
    position: relative;
}

.unmute-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 3px;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.content-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 90%
}

.video-container {
    width: 100%;
    margin: 0 auto;
}

.video {
    width: 100%;
    object-fit: contain;
}

.right-content {
    display: flex;
    flex: 1;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
}

.buttons {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: 90%;
    margin-top: 20px;
    flex-wrap: wrap;
}

.download-btn {
    background-color: var(--denim);
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}