/* Variables */

:root {
    --black: rgba(0, 0, 0, 1);
    --eerie-black: rgba(25, 25, 25, 1);
    --denim: rgba(45, 114, 184, 1);
    --denim-hover: rgb(66, 137, 207);
    --steel-blue: rgba(82, 124, 181, 1);
    --white: rgba(255, 255, 255, 1);
    --black-2: rgba(0, 0, 0, 0.2);
    --black: rgba(0, 0, 0, 1);
    --gravel-2: rgba(71, 71, 71, 0.298);
    --gravel: rgba(71, 71, 71, 1);
    --steel-blue-2: rgba(82, 124, 181, 0.502);
    --polo-blue: rgba(135, 162, 201, 1);
    --bluvec: rgba(217, 217, 217, 1);
    --zest: rgba(221, 134, 32, 1);
    --pizazz: rgba(255, 138, 0, 1);
    --pizazz1: rgba(255, 138, 0, 0.3);
    --pizazz2: rgba(255, 138, 0, 0.5);
    --pizazz3: rgba(255, 138, 0, 1);
    --pizazz4: rgba(255, 138, 0, 1);
    --indigo: rgb(146, 39, 223, 1);
    --indigo1: rgb(146, 39, 223, 0.3);
    --indigo2: rgb(146, 39, 223, 0.5);
    --indigo3: rgb(146, 39, 223, 1);
    --indigo4: rgb(146, 39, 223, 1);
    --shine1: rgb(255, 255, 255, 0.3);
    --shine2: rgb(255, 255, 255, 0.5);
    --shine3: rgb(255, 255, 255, 1);
    --shine4: rgb(255, 255, 255, 1);
    --red: rgb(243, 53, 53, 1);
    --red1: rgb(243, 53, 53, 0.3);
    --red2: rgb(243, 53, 53, 0.5);
    --red3: rgb(243, 53, 53, 1);
    --red4: rgb(243, 53, 53, 1);
    --heavy-metal: rgba(70, 71, 62, 0.5);
    --blue: rgb(20, 142, 241);
    --thunder: rgba(46, 46, 46, 1);
    --bgBlue: rgba(45, 114, 184, 0.46);
    --transparent: transparent;

    --font-size-xs: 10px;
    --font-size-s: 14px;
    --font-size-m: 20px;
    --font-size-l: 25px;
    --font-size-xl: 30px;
    --font-size-xxl: 38px;
    --font-size-xxxl: 48px;

    --font-family-inter: "Inter";
    --font-family-mont-bold: "Mont-Bold";
    --font-family-mont-book: "Mont-Book";
    --font-family-mont-light: "Mont-Light";
    --font-family-mont-regular: "Mont-Regular";
    --font-family-mont-semibold: "Mont-SemiBold";
    --font-family-mont-black: "Mont-Black";

    --max-width: 1438px;
    --max-bgwidth: 100%;
}

/* Classes */

.mont-semi-bold-white-45px {
    color: var(--white);
    font-family: var(--font-family-mont-semibold);
    font-size: 45px;
    font-weight: 600;
    font-style: normal;
}

.mont-semi-bold-denim-48px {
    color: var(--denim);
    font-family: var(--font-family-mont-semibold);
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
}

.mont-semi-bold-denim-36px {
    color: var(--denim);
    font-family: var(--font-family-mont-semibold);
    font-size: 36px;
    font-weight: 600;
    font-style: normal;
}

.mont-regular-normal-white-18px {
    color: var(--white);
    font-family: var(--font-family-mont-regular);
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 2;
}

.mont-regular-normal-red-18px {
    color: var(--red);
    font-family: var(--font-family-mont-regular);
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 2;
}

.mont-regular-normal-white-20px {
    color: var(--white);
    font-family: var(--font-family-mont-regular);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
}

.mont-bold-white-80px {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: 80px;
    font-weight: 700;
    font-style: normal;
}

.mont-bold-white-48px {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    font-style: normal;
}

.mont-bold-white-38px {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
}

.mont-bold-white-30px {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
}

.inter-normal-white-20px {
    color: var(--white);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
}

.mont-semi-bold-white-70px {
    color: var(--white);
    font-family: var(--font-family-mont-semibold);
    font-size: 70px;
    font-weight: 600;
    font-style: normal;
}

.mont-light-white-14px {
    color: var(--white);
    font-family: var(--font-family-mont-light);
    font-size: var(--font-size-s);
    font-weight: 300;
    font-style: normal;
}

.inter-extra-bold-white-48px {
    color: var(--white);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxxl);
    font-weight: 800;
    font-style: normal;
}

.inter-bold-denim-16px {
    color: var(--denim);
    font-family: var(--font-family-inter);
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
}

.mont-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: var(--font-size-s);
    font-weight: 700;
    font-style: normal;
}

.mont-semi-bold-white-25px {
    color: var(--white);
    font-family: var(--font-family-mont-semibold);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
}

.mont-bold-white-9px {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: 9px;
    font-weight: 700;
    font-style: normal;
}

.mont-semi-bold-white-23px {
    color: var(--white);
    font-family: var(--font-family-mont-semibold);
    font-size: 23px;
    font-weight: 600;
    font-style: normal;
}

.mont-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
}

.mont-bold-denim-14px {
    color: var(--denim) !important;
    font-family: var(--font-family-mont-bold);
    font-size: var(--font-size-s);
    font-weight: 700;
    font-style: normal;
}

.inter-extra-bold-white-30px {
    color: var(--white);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl);
    font-weight: 800;
    font-style: normal;
}

.mont-regular-normal-white-25px {
    color: var(--white);
    font-family: var(--font-family-mont-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
}

.mont-regular-normal-white-30px {
    color: var(--white);
    font-family: var(--font-family-mont-regular);
    font-size: var(--font-size-xl);
    font-weight: 400;
    font-style: normal;
}

.mont-black-denim-80px {
    color: var(--denim);
    font-family: var(--font-family-mont-black);
    font-size: 80px;
    font-weight: 800;
    font-style: normal;
}

.mont-black-pizazz-200px {
    color: var(--pizazz);
    font-family: var(--font-family-mont-black);
    font-size: 200px;
    font-weight: 900;
    font-style: normal;
}

.mont-black-pizazz-80px {
    color: var(--pizazz);
    font-family: var(--font-family-mont-black);
    font-size: 80px;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Mont-Book";
    font-style: normal;
    font-weight: 400;
    src: url(/font/Mont-Book.otf) format("opentype");
}

@font-face {
    font-family: "Mont-Bold";
    font-style: normal;
    font-weight: 700;
    src: url(/font/Mont-Bold.otf) format("opentype");
}

@font-face {
    font-family: "Mont-Light";
    font-style: normal;
    font-weight: 300;
    src: url(/font/Mont-Light.otf) format("opentype");
}

@font-face {
    font-family: "Mont-Regular";
    font-style: normal;
    font-weight: 400;
    src: url(/font/Mont-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Mont-SemiBold";
    font-style: normal;
    font-weight: 600;
    src: url(/font/Mont-SemiBold.otf) format("opentype");
}

@font-face {
    font-family: "Mont-Black";
    font-style: normal;
    font-weight: 900;
    src: url(/font/Mont-Black.ttf) format("truetype");
}

* {
    box-sizing: border-box;
}