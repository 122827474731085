.solution-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 100px;
    column-gap: 80px;
    row-gap: 100px;
    z-index: 999;
    width: var(--max-width);
    margin: auto;
    max-width: 100vw;
}

.solution-title {
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    z-index: 99;
}

.solution-item {
    max-width: 90vw;
    width: 400px;
    height: 247px;
    background-size: 440px;
    border: 0px;
    text-align: center;
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: 36px;
    font-weight: 700;
    font-style: normal;
    cursor: pointer;
    transition: 200ms;
}

.solution-item:hover {
    margin-top: -20px;
    box-shadow: 4px -40px 60px 5px rgb(26, 117, 206);
    height: 267px;
}

.ellipse-16 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 118px;
    position: absolute;
    top: 126px;
    width: 363px;
}

.ellipse-17 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 560px;
    position: absolute;
    top: 27px;
    width: 363px;
}

.ellipse-18 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 0;
    position: absolute;
    top: 19px;
    width: 363px;
}

.ellipse-20 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 339px;
    position: absolute;
    top: 26px;
    width: 363px;
}

.ellipse-21 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 636px;
    position: absolute;
    top: 180px;
    width: 363px;
}

.ellipse-26 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 939px;
    position: absolute;
    top: 19px;
    width: 363px;
}

.ellipse-22 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 742px;
    position: absolute;
    top: 34px;
    width: 363px;
}

.ellipse-27 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 1135px;
    position: absolute;
    top: 19px;
    width: 363px;
}