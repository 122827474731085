.slideshow-container {
    position: relative;
}

.slide-image {
    width: 100%;
    height: 750px;
    max-height: 50vw;
}

.slide-prev,
.slide-next {
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 20px;
    margin-left: 20px;
    color: white;
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.slide-next {
    margin-right: 20px;
    right: 0;
    border-radius: 3px 0 0 3px;
}

.slide-prev:hover,
.slide-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.slide-dot {
    cursor: pointer;
    height: 15px;
    max-height: 3vw;
    width: 15px;
    max-width: 3vw;
    margin: 30px 10px;
    background-color: #717171;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot-active,
.slide-dot:hover {
    background-color: var(--denim);
}

.home-intro {
    background-color: var(--bgBlue);
    text-align: center;
    padding: 30px 30px;
    line-height: 1.2;
    margin-top: 50px;
    margin-bottom: 50px;
}

.home-intro-text {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.home-solution {
    text-align: center;
}

.home-why {
    padding: 30px 0px;
    max-width: var(--max-width);
}

.home-why-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 100px;
    justify-content: center;
    column-gap: 130px;
}

.home-why-item {
    width: 450px;
    line-height: 1.4;
    max-width: 90vw;
}

.home-f4 {
    padding: 30px 0px;
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: var(--max-width);
}

.home-four-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 580px;
    row-gap: 15px;
    padding: 80px;
    border-radius: 3%;
    background: linear-gradient(rgba(45, 114, 184, 0.46), rgba(45, 114, 184, 0.01));
}

.home-four-item {
    border: 2px solid #bbb3b3;
    border-radius: 20px;
    width: 200px;
    height: 200px;
    text-align: center;
    cursor: pointer;
}

.slide-video {
    width: 100%;
    height: calc(100vw / 1.77);
}

.slide-text {
    position: absolute;
    left: 10%;
    top: 20%;
}