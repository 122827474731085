.siteContent {
    background-color: var(--eerie-black);
    display: flex;
    flex-direction: column;
    margin-top: 87px;
}

/* Products Overview CSS*/
.overview-title {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    letter-spacing: 0;
    width: 100%;
    text-align: center;
}

.overview-image {
    margin-left: auto;
    margin-right: auto;
}

.overview-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 50px;
    column-gap: 20px;
    padding: 100px 10px;
    align-items: center;
    justify-content: center;
    width: var(--max-width);
    max-width: 90vw;
    align-self: center;
}

.overview-item {
    margin-top: 100px;
    width: 650px;
    min-height: 500px;
    z-index: 999;
    line-height: 1.6;
    min-height: 500px;
}

.overview-item-text {
    margin-top: 50px;
    margin-bottom: 100px;
    line-height: 1.6;
}

.blusensor-learnMore-wrapper {
    height:80px;
    padding:10px;
}

.blusensor-learnMore {
    background-color: var(--denim);
    border-radius: 30px;
    padding: 14px 36px;
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-weight: 700;
    font-style: normal;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    transition: 200ms;
}

.blusensor-learnMore:hover {
    background-color: var(--denim-hover);
    padding: 18px 46px;
    word-spacing: 6px;
    margin-top: -2px;
}

.blusensor-learnMore2 {
    background-color: var(--denim);
    border-radius: 30px;
    padding: 0px 16px;
    color: var(--white);
    transition: 300ms;
    font-size: 15px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    justify-content: flex-start;
}

.blusensor-learnMore2:hover {
    background-color: var(--denim-hover);
    column-gap: 15px;
    margin-left: 7px;
}

.ellipse-14 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 565px;
    position: absolute;
    top: 137px;
    width: 363px;
}

/* Products Specific CSS */
.basicInfo {
    position: relative;
    width: 100%;
    padding: 150px 0px;
    min-height: 500px;
}

.basicInfo-text {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 20px;
    letter-spacing: 0;
    width: 700px;
    height: auto;
}

.basicInfo-wrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    max-width: 90vw;
    align-items: center;
}

.basicInfo-text-1 {
    color: var(--white);
    font-family: var(--font-family-mont-semibold);
    font-size: 35px;
    font-weight: 600;
    z-index: 99;
}

.basicInfo-text-2 {
    color: var(--white);
    font-family: var(--font-family-mont-bold);
    font-size: 80px;
    font-weight: 700;
    z-index: 99;
    word-break: break-all
}

.basicInfo-text-3 {
    color: var(--white);
    font-family: var(--font-family-mont-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    z-index: 99;
    line-height: 1.4;
}

.basicInfo-image {
    width: 323px;
    z-index: 99;
}

.basicInfo-background {
    height: 575px;
    object-fit: cover;
    position: absolute;
    top: 12px;
    width: var(--max-bgwidth);
}

.detail {
    width: var(--max-width);
    position: relative;
    display: flex;
    flex-flow: row wrap;
    column-gap: 10px;
    row-gap: 30px;
    align-self: center;
    max-width: 90vw;
    justify-content: center;
    padding: 50px 0;
}

.detail-image {
    margin-left: 28px;
    width: 530px;
}

.detail-text {
    width: 700px;
    z-index: 999;
    letter-spacing: 0;
    line-height: 50px;
}

.detail-transition-1 {
    background-color: #87a2c9;
    border: 1px none;
    border-radius: 104.5px/82px;
    filter: blur(80px);
    height: 164px;
    left: 467px;
    position: absolute;
    top: 109px;
    width: 209px;
}

.detail-transition-2 {
    background-color: var(--steel-blue);
    border: 1px none;
    border-radius: 216px/174px;
    filter: blur(200px);
    height: 348px;
    left: 1121px;
    position: absolute;
    top: 348px;
    width: 432px;
}

.download-wrapper {
    margin-top: 50px;
    height: 100px;
}

.detail-download {
    background-color: var(--denim);
    border-radius: 30px;
    border: 0px;
    display: block;
    padding: 16px 14px;
    letter-spacing: 0;
    margin-left: auto;
    margin-right: auto;
    transition: 200ms;
}

.detail-download:hover {
    padding: 20px 20px;
    word-spacing: 5px;
    background-color: var(--denim-hover);
}

.featureBlock {
    margin-top: 50px;
    padding: 80px 0px;
    min-height: 500px;
    background-size: cover;
    background-image: url(/images/feature_background.png);
}

.feature-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    column-gap: 120px;
    row-gap: 0px;
    width: var(--max-width);
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.feature-item {
    width: 280px;
    height: 320px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    row-gap: 20px;
    z-index: 99;
    cursor: pointer;
}

.feature-item-text {
    line-height: 1.2;
    font-size: 15px;
    letter-spacing: 0.5px;
}

.feature-hover {
    background-color: rgba(65, 62, 62, 0.671);
    border-radius: 15px;
    padding: 10px 20px;
}

.feature-image {
    height: 80px;
    align-self: center;
    cursor: pointer;
}

.howItWorks {
    height: auto;
    object-fit: cover;
    position: relative;
    margin-top: 100px;
}

.howItWorks-background {
    background-color: #4747474c;
    border: 1px none;
    filter: blur(50px);
    height: 654px;
    left: 0;
    position: absolute;
    top: 0px;
    width: 1476px;
}

.howItWorks-text-1 {
    margin-top: 20px;
    color: var(--denim);
    font-family: var(--font-family-mont-semibold);
    font-size: var(--font-size-l);
    font-weight: 600;
}

.howItWorks-text-2 {
    margin-top: 20px;
    color: var(--denim);
    font-family: var(--font-family-mont-regular);
    font-size: var(--font-size-m);
    font-weight: 400;
}

.howItWorks-flex {
    display: flex;
    flex-flow: row wrap;
    margin-top: 80px;
    column-gap: 30px;
    row-gap: 30px;
    justify-content: center;
}

.howItWorks-item {
    width: 200px;
    z-index: 999;
    text-align: center;
}

.howItWorks-detail {
    margin-top: 20px;
    margin-bottom: 40px;
    height: 25px;
}

.technical {
    position: relative;
    margin-top: 200px;
    width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    max-width: 90vw;
}

.technical-transition {
    background-color: #527cb580;
    border: 1px none;
    border-radius: 181.5px/153.5px;
    filter: blur(200px);
    height: 307px;
    left: 700px;
    position: absolute;
    top: 0;
    width: 363px;
}

.technical-title {
    text-align: center;
}

.technical-middle-wrapper {
    margin-left: -100px;
    margin-right: -100px;
    margin-top: 200px;
    z-index: 999;
}

.technical-image {
    height: 726px;
}

.technical-image-general {
    height: 352px;
    left: 700px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 352px;
}

.technical-flex {
    margin-top: 100px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.technical-flex2 {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: end;
}

.technical-item {
    display: flex;
    flex-flow: column nowrap;
    width: 450px;
    height: 120px;
    row-gap: 8px;
    align-items: center;
    justify-content: center;
    max-width: 90vw;
}

.technical-item-general {
    display: flex;
    flex-flow: column nowrap;
    width: 400px;
    height: 120px;
    row-gap: 8px;
    align-items: center;
    justify-content: center;
    border: 0px;
    border-top: 4px solid var(--denim);
    border-right: 2px solid var(--denim);
    max-width: 90vw;
}

.technical-text-1 {
    color: var(--bluvec);
    font-family: var(--font-family-mont-light);
    font-size: 23px;
    font-weight: 300;
    font-style: normal;
}

.technical-text-2 {
    line-height: 1.2;
    text-align: center;
}

.technical-text-3 {
    letter-spacing: 0;
    cursor: pointer;
    color: var(--denim) !important;
}

.technical-download {
    background-color: black;
    border-radius: 20px;
    width: 250px;
    height: 80px;
    display: flex;
    transition: 400ms;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.technical-download:hover {
    width: 270px;
    height: 90px;
    margin-top: 45px;
}

.technical-download-image {
    background-color: var(--denim);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 30%;
    border: 1px solid var(--denim);
    padding: 15px 12px;
    cursor: pointer;
    margin-left: -7px;
    position: relative;
}

.download-icon {
    margin-top: 2px;
    margin-left: 5px;
}

.download-icon:hover {
    animation: bounce 2s ease infinite;
}

.technical-download-text {
    line-height: 30px;
    position: relative;
    height: 100%;
    width: 70%;
}

.technical-option {
    display: flex;
    column-gap: 30px;
    margin-top: 40px;
    justify-content: center;
}

.technical-option-item {
    border: 4px solid var(--denim);
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
}

.software-illustrate {
    padding: 40px 20px;
    column-gap: 60px;
    row-gap: 30px;
    align-items: center;
}

.cloud-plan-item {
    border: 1px solid #424242;
    background-color: rgba(42, 42, 42, 0.42);
    border-radius: 30px;
    width: 320px;
    height: 400px;
    padding: 40px 20px;
}