.bounce {
    animation: bounce 2s ease infinite;
}

@keyframes bounce {
    70% {
        transform: translateY(0%);
    }

    80% {
        transform: translateY(-15%);
    }

    90% {
        transform: translateY(0%);
    }

    95% {
        transform: translateY(-7%);
    }

    97% {
        transform: translateY(0%);
    }

    99% {
        transform: translateY(-3%);
    }

    100% {
        transform: translateY(0);
    }
}

.bounce2 {
    animation: bounce2 1.6s ease infinite;
}

@keyframes bounce2 {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15%);
    }

    60% {
        transform: translateY(-8%);
    }
}

.bounce-icon {
    animation: bounce-icon 1.5s ease infinite;
}

@keyframes bounce-icon {
    30% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-15%);
    }

    60% {
        transform: translateY(0%);
    }

    70% {
        transform: translateY(-7%);
    }

    80% {
        transform: translateY(0%);
    }

    90% {
        transform: translateY(-3%);
    }

    100% {
        transform: translateY(0);
    }
}

.bounce-in-right {
    animation: bounce-in-right 2s ease;
}

.bounce-in-left {
    animation: bounce-in-left 2s ease;
}

@keyframes bounce-in-right {
    0% {
        opacity: 0;
        transform: translateX(150px);
    }

    60% {
        opacity: 1;
        transform: translateX(-20px);
    }

    80% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes bounce-in-left {
    0% {
        opacity: 0;
        transform: translateX(-150px);
    }

    60% {
        opacity: 1;
        transform: translateX(20px);
    }

    80% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0);
    }
}

.fade-in-left {
    animation: fade-in-left 1s ease;
}

@keyframes fade-in-left {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-right {
    animation: fade-in-right 1s ease;
}

@keyframes fade-in-right {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-up {
    animation: fade-in-up 2s ease;
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up-big {
    animation: fade-in-up-big 3s ease;
}

@keyframes fade-in-up-big {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-down {
    animation: fade-in-down 2s ease;
}

@keyframes fade-in-down {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-down2 {
    animation: fade-in-down2 0.3s ease;
}

@keyframes fade-in-down2 {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fade-in 2s ease;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fade-out 2s linear infinite;
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.bounce-in {
    animation: bounce-in 2s ease;
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(.3);
    }

    50% {
        opacity: 1;
        transform: scale(1.05);
    }

    70% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}

.header-bounce-in {
    animation: header-bounce-in 1s ease;
}

@keyframes header-bounce-in {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    60% {
        opacity: 1;
        transform: translateX(5px);
    }

    80% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.circle {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: whiteSmoke;
    box-shadow: 4px -40px 60px 5px rgb(26, 117, 206) inset;
}

.loader {
    background: linear-gradient(to right, rgb(22, 113, 202) 50%, transparent 50%);
    animation: spin 1s infinite linear;
}

.loader:before {
    display: block;
    content: '';
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    background: var(--eerie-black);
    border-radius: 50%;
}

.spin {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes wateranimate {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0.6);
        opacity: 0.8;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(1.5);
        opacity: 0;
    }
}

.water1 {
    -webkit-animation: wateranimate 3s 3s ease-out infinite;
    animation: wateranimate 2.5s 1.2s ease-out infinite;
}

.water2 {
    -webkit-animation: wateranimate 3s 2s ease-out infinite;
    animation: wateranimate 2.5s 0.8s ease-out infinite;
}

.water3 {
    -webkit-animation: wateranimate 3s 1s ease-out infinite;
    animation: wateranimate 2.5s 0.4s ease-out infinite;
}

.water4 {
    -webkit-animation: wateranimate 3s 0s ease-out infinite;
    animation: wateranimate 2.5s 0s ease-out infinite;
}

.water1,
.water2,
.water3,
.water4 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid;
    border-color: inherit;
    box-shadow: 0 0 120px 30px rgba(235, 31, 137, 1, 1) inset;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
}