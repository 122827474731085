.technology-overview {
    height: auto;
    padding: 100px 20px;
    text-align: center;
    width: var(--max-width);
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.technology-overview-dsi {
    position: relative;
    width: 100%;
    height: auto;
    background-size: cover;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 30px 0px;
    column-gap: 50px;
}

.technology-overview-text {
    width: 600px;
    max-width: 90vw;
}

.technology-overview-text-block {
    height: 700px;
    position: relative;
    border: 10px solid black;
    background: rgba(0, 0, 0, 0.349);
    padding: 50px 50px;
    line-height: 1.4;
}

.technology-learn-more {
    font-size: 30px;
    margin-left: 150px;
}

.technology-workflow {
    position: relative;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 200px;
    width: 1000px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.tech-workflow-flex {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: -40px;
    position: relative;
}

.tech-flow-item1 {
    position: relative;
    align-items: center;
    background-color: var(--heavy-metal);
    border: 0.5px none;
    border-radius: 20px;
    box-shadow: inset 0px 0px 0px 1px #484848, 0px 9px 30px #00000029;
    height: 130px;
    padding: 0 23px;
    width: 320px;
    margin-left: -20px;
}

.tech-flow-item2 {
    background-color: var(--denim);
    border: 1px none;
    border-radius: 6px;
    padding: 12px 10px;
    width: fit-content;
    text-align: center;
    /*center item vertically*/
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: auto;
}

.rectangle {
    position: absolute;
    background-color: var(--denim);
    border-radius: 10px;
    height: 40px;
    width: 40px;
}

.rectangle-p1 {
    left: -20px;
    top: -20px;
}

.rectangle-p2 {
    right: -20px;
    top: -20px;
}

.rectangle-p3 {
    left: -20px;
    bottom: -20px;
}

.rectangle-p4 {
    right: -20px;
    bottom: -20px;
}

.technology-circle {
    border: 2px solid;
    border-radius: 100%;
    background-color: transparent;
}

.tech-c1 {
    height: 234px;
    width: 234px;
    border-color: var(--indigo1);
    transition: 300ms;
}

.tech-c2 {
    height: 192px;
    width: 192px;
    border-color: var(--indigo2);
    transition: 300ms;
}

.tech-c3 {
    height: 150px;
    width: 150px;
    border-color: var(--indigo);
    transition: 300ms;
}

.tech-c4 {
    height: 112px;
    width: 112px;
    border-color: var(--indigo);
    background-color: var(--indigo);
    transition: 300ms;
}

.tech-detail {
    background-image: url(/images/earth_purple.png);
    background-size: 1400px;
    padding: 100px 100px;
}

.tech-detail-text {
    background-color: rgba(46, 46, 46, 0.62) !important;
    padding: 80px 40px !important;
    line-height: 1.6;
}

.tech-feature {
    padding: 100px 100px;
}

.tech-feature-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 50px;
    column-gap: 30px;
}

.tech-feature-item {
    width: 600px;
}

.tech-relevant {
    background-image: url(/images/technology_relevant_bg1.png);
    background-size: cover;
    padding: 100px 10px;
    min-height: 800px;
}

.tech-relevant-flex {
    display: flex;
    flex-flow: row wrap;
    row-gap: 50px;
    column-gap: 20px;
}

.tech-relevant-item {
    background-image: url(/images/technology_relevant_bg.svg);
    background-size: 410px;
    width: 400px;
    height: 520px;
    max-width: 90vw;
}

.image-center {
    margin-left: auto;
    margin-right: auto;
}

.tech-relevant-text {
    font-size: 30;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
}