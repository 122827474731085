.partner-title {
    background-image: url(/images/partner-bg.png);
    padding: 150px 0px;
    text-align: center;
    line-height: 1.4;
    background-size: cover;
}

.partner-intro {
    padding: 60px 80px;
    line-height: 1.4;
}

.partner-flex {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    justify-content: center;
    column-gap: 50px;
    row-gap: 30px;
}

.partner-flex-item {
    background-color: var(--thunder);
    width: 428px;
    height: 456px;
    padding: 50px 50px;
    line-height: 1.4;
}

.partner-logo {
    text-align: center;
    padding: 100px 80px;
}

.partner-logo-flex {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    justify-content: center;
    column-gap: 50px;
    row-gap: 30px;
    margin-bottom: 100px;
}

.partner-logo-item {
    width: 210;
    height: 95;
}

.partner-event-flex {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    justify-content: center;
    column-gap: 100px;
    row-gap: 30px;
    margin-bottom: 100px;
}

.partner-event-item {
    background-color: var(--thunder);
    width: 320px;
    height: 380px;
}

.partner-event-text {
    padding: 50px 50px;
    text-align: center;
    line-height: 1.6;
}

.partner-card {
    background-color: var(--thunder);
    border-radius: 30px;
    padding: 80px 80px;
    border: 1px solid;
    border-color: #484848;
}

.partner-card2 {
    display: flex;
    flex-flow: row wrap;
    column-gap: 10px;
    row-gap: 30px;
    align-items: center;
    text-align: start;
    width: 1280px;
    max-width: 90vw;
}

.partner-card-inputFlex {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.partner-card-key {
    width: 180px;
    min-width: 180px;
}

.partner-form-checkbox {
    width: 100%;
}

.par-fea-item {
    margin-top: 50px;
    width: 250px;
    text-align: center;
}

.partner-form-btn {
    width: 150px;
    height: 50px;
    background-color: var(--denim);
    font-size: 30px;
    border-radius: 12px;
    border: 0px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.partner-form-input {
    max-width: 100%;
    min-width: 200px;
}

/* Partner Login */
.partner-login {
    padding: 90px 80px;
    margin-bottom: 200px;
    display: flex;
    flex-flow: row wrap;
    column-gap: 50px;
    row-gap: 40px;
}

.partner-login-card {
    width: 700px;
    display: flex;
    flex-flow: row wrap;
    row-gap: 30px;
    align-items: center;
}

.partner-login-benefit {
    padding: 50px 40px;
    width: 430px;
    height: 560px;
    line-height: 2.6;
    background-color: var(--thunder);
    border-radius: 5px;
}

/* Html Input */
input[type="text"],
input[type="email"],
input[type="password"] {
    background: transparent;
    border: 2px solid var(--denim);
    border-radius: 5px;
    height: 36px;
    width: 300px;
    color: white;
    padding: 12px 8px;
    outline: none;
}

input[type="text"]:focus {
    border: 1px solid rgb(193, 229, 241);
}

[type="checkbox"] {
    width: 50px;
    height: 16px;
    cursor: pointer;
    background-color: transparent;
    accent-color: var(--denim);
}

input:invalid {
    border: 1px solid red !important;
}

textarea {
    background: transparent;
    border: 2px solid var(--denim);
    border-radius: 5px;
    width: 100%;
    color: white;
    padding: 12px 8px;
    outline: none;
}

textarea:focus {
    border: 1px solid rgb(193, 229, 241);
}