$breakpoints: (
    small: 480px,
    medium: 640px,
    large: 820px,
    phone: 600px,
    tablet: 768px,
    desktop: 1024px
);

@include media('<=phone') {

    h1 {
        font-size: 36px !important;
    }

    h2 {
        font-size: 24px !important;
        margin-top: 105px !important;
        margin-bottom: 10px !important;
    }

    h3 {
        font-size: 24px !important;
    }

    .home-four-item {
        width: 160px !important;
        height: 190px !important;
    }

    .home-four-flex {
        padding: 20px 20px !important;
        width: 380px !important;
    }

    .basicInfo-text-2 {
        font-size: 36px !important;
    }

    .overview-flex {
        padding: 0 !important;
    }

    .overview-item {
        min-height: 300px !important;
    }

    .overview-item-text {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .blusensor-learnMore {
        font-size: 16px !important;
    }

    .basicInfo-text-3 {
        font-size: 20px !important;
    }

    .basicInfo {
        padding: 50px 0px !important;
    }

    .basicInfo-wrapper {
        flex-flow: row wrap !important;
    }

    .basicInfo-image {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .detail {
        padding: 0 !important;
    }

    .detail-image {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .tech-flow-item2 {
        font-size: 14px !important;
        max-width: 150px !important;
    }

    .tech-c1 {
        width: 60px !important;
        height: 60px !important;
    }

    .tech-c2 {
        width: 50px !important;
        height: 50px !important;
    }

    .tech-c3 {
        width: 40px !important;
        height: 40px !important;
    }

    .tech-c4 {
        width: 30px !important;
        height: 30px !important;
    }

    .tech-flow-item1 {
        width: 120px !important;
    }

    .resource-bg {
        padding: 120px 50px !important;
    }

    .marketing-FAQ {
        padding: 50px 10px !important;
    }

    .marketing-ppt {
        padding: 20px 20px !important;
    }

    .resource-option-item {
        width: 150px !important;
    }

    .partner-card {
        padding: 20px 30px !important;
    }

    .partner-intro {
        padding: 40px 20px !important;
    }

    .partner-logo {
        padding: 40px 20px !important;
    }

    .request-title {
        font-size: 30px !important;
    }

    .request-content {
        font-size: 24px !important;
    }

    .slide-text {
        left: 15% !important;
    }

    .mont-bold-white-80px {
        font-size: 40px !important;
    }

    .mont-semi-bold-white-70px {
        font-size: 40px !important;
    }

    .mont-bold-white-48px {
        font-size: 34px !important;
    }

    .mont-bold-white-38px {
        font-size: 26px !important;
    }

    .mont-bold-white-30px {
        font-size: 20px !important;
    }

    .mont-semi-bold-white-45px {
        font-size: 30px !important;
    }

    .mont-semi-bold-denim-48px {
        font-size: 30px !important;
    }

    .mont-semi-bold-denim-36px {
        font-size: 25px !important;
        line-height: 1.4 !important;
    }

    .mont-semi-bold-white-25px {
        font-size: 16px !important;
    }

    .mont-regular-normal-white-25px {
        font-size: 16px !important;
    }

    .mont-regular-normal-white-30px {
        font-size: 20px !important;
    }

    .mont-bold-white-20px {
        font-size: 12px !important;
    }

    .mont-regular-normal-white-18px {
        font-size: 16px !important;
    }

    .mont-black-pizazz-200px {
        font-size: 100px !important;
    }

    .inter-normal-white-20px {
        font-size: 18px !important;
    }

    .technology-circle {
        font-size: 20px !important;
    }

}